import React from "react";
import "./icons.m.css";

export function KeyboardDownIcon({
  cssName = "keyboard-down-icon",
  color = "white",
  fillColor = "white",
  id = "key_dd"
}) {
  return (
    <div id={id} styleName={cssName}>
      <svg id={id} viewBox="0 0 12 7" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
        <path id={id} d="M11 1L6 6L1 1" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
}

export function ArrowDropDown({
  cssName = "arrow-drop-down-icon",
  color = "white",
  fillColor = "white",
  id = "arr_dd"
}) {
  return (
    <div id={id}>
      <svg id={id} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
        <path id={id} d="M0 0h24v24H0z" fill="none" />
        <path id={id} d="M7 10l5 5 5-5z" />
      </svg>
    </div>
  );
}
