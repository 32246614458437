import React from "react";
import { Link } from "@quintype/components";
import "./archivedlistitem.m.css";
import { formatDate } from "../../helper/date-helper";

const ArchivedListItem = ({ article }) => {
  return (
    <React.Fragment>
      <div className="flex-container" styleName="root">
        <div styleName="image-container col-1">
          <Link href={`/archive/articles/${article.slug}`}>
            <img
              styleName="image"
              src={"https://afk-api.afkgaming.com/" + article.coverimage}
              height="110"
              width="187"
              alt="cover image"
            />
          </Link>
        </div>
        <div className="flex-mobile" styleName="col-2">
          <p styleName="title">
            <Link href={`/archive/articles/${article.slug}`} styleName="title" style={{ color: "inherit" }}>
              {article.name}
            </Link>
          </p>
          <p style={{ display: "flex", alignItems: "center", margin: 0, marginTop: "10px" }}>
            by&nbsp;
            <strong>
              <span styleName="author" style={{ color: "inherit" }}>
                {article.user.username}
              </span>
            </strong>
            &nbsp; &nbsp;
            <span styleName="date" style={{ cursor: "default" }}>
              {formatDate(article.updatedAt, "DD MMM YYYY")}
            </span>
          </p>
          <div styleName="excerpt" dangerouslySetInnerHTML={{ __html: article.excerpt }} />
        </div>
      </div>
      <hr />
    </React.Fragment>
  );
};

export default ArchivedListItem;
