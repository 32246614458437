import React from "react";
import "./search.m.css";
import { Search } from "../../basic/icons/search";
import Dropdown from "../../molecules/dropdown/dropdown";
import StoryCard from "../../molecules/StoryCard";
import { getNestedSectionsFromConfig } from "../../helper/section-helper";
import { Link } from "@quintype/components";
import axios from "axios";
import Button from "../../atoms/Button";
import LazyLoad from "react-lazyload";
import { sendNonStoryPageView } from "../../helper/helper";

const getSearchPath = (query, author, section, sectionIds, authorId) => {
  if (typeof window !== "undefined") {
    let url = new URL(window.location.href);
    let urlParams = url.searchParams;

    if (query) {
      urlParams.set("q", query);
    }

    if (section && section !== "All Sections") {
      urlParams.set("section-name", section);
      let urlIds = "";
      sectionIds.forEach((element) => {
        urlIds = urlIds + element + ",";
      });
      urlParams.set("section-id", urlIds);
    } else if (section === "All Sections") {
      urlParams.delete("section-name");
      urlParams.delete("section-id");
    }

    if (author && author !== "All Authors") {
      urlParams.set("author", author);
      urlParams.set("author-id", authorId);
    } else if (author === "All Authors") {
      urlParams.delete("author");
      urlParams.delete("author-id");
    }

    return url.toString();
  }
};

const SearchPage = (props) => {
  let [page, setPage] = React.useState(1);
  let [stories, setStories] = React.useState(props.data.stories);
  let [searchText, setSearchText] = React.useState("");
  let [authorName, setAuthor] = React.useState("All Authors");
  let [sectionName, setSection] = React.useState("All Sections");
  let [sectionIds, setSectionIds] = React.useState([]);
  let [sectionData, setSectionData] = React.useState([]);
  let [loading, setLoading] = React.useState(false);
  let [authorId, setAuthorId] = React.useState(0);
  let [authorData, setAuthorData] = React.useState([]);

  React.useEffect(() => {
    let searchData = props.data;

    dataLayer.push({ event: "site-search-trigger", searchQuery: searchData.query || "" });
    setSearchText(searchData.query || "");
    let authorList = [{ id: 1, name: "All Authors" }, ...props.data.authors];
    setAuthorData(authorList);

    let sectionList = [{ id: 1, name: "All Sections" }, ...getNestedSectionsFromConfig(props.config.sections)];
    setSectionData(sectionList);

    let sectionName = "All Sections";
    if (searchData.sectionName) {
      const selectedSection = sectionList.find((item) => {
        return item.name === searchData.sectionName;
      });

      sectionName = selectedSection.name;
      setSectionIds([selectedSection.id, ...selectedSection.childIds]);
    }
    setSection(sectionName);

    if (searchData.authorId) {
      setAuthor(searchData.authorName);
      setAuthorId(searchData.authorId);
    }

    sendNonStoryPageView();
  }, []);

  async function loadMore() {
    setLoading(true);
    const offset = page * 12;
    let requestUrl = `/api/v1/advanced-search?limit=12&offset=${offset}`;

    if (sectionName !== "All Sections") {
      let urlIds = "";
      sectionIds.forEach((element) => {
        urlIds = urlIds + element + ",";
      });
      requestUrl = requestUrl + `section-id=${sectionIds}`;
    }
    if (authorName !== "All Authors") {
      requestUrl = requestUrl + `author-id=${authorId}`;
    }
    if (searchText.length > 0) {
      requestUrl = requestUrl + `&q=${searchText}`;
    }
    const { data } = await axios.get(requestUrl);
    setPage(page + 1);
    setStories([...stories, ...data.items]);
    setLoading(false);
  }

  function updateAuthor(author) {
    setAuthor(author.name);
    setAuthorId(author.id);
  }

  function updateSection(section) {
    setSection(section.name);
    setSectionIds([section.id, ...section.childIds]);
  }

  let storyDiv = null;
  let showDivs = false;
  const searchData = props.data;

  if (stories.length > 0) {
    showDivs = true;
    storyDiv = stories.map((story, index) => {
      let searchItem = (
        <LazyLoad key={index} height={120} offset={50} styleName="search-card-root">
          <StoryCard key={index} story={story} responsive showTime cssName="tag-grid-root" />
        </LazyLoad>
      );
      return searchItem;
    });
  } else {
    storyDiv = <div>No stories found</div>;
  }

  const total = searchData.total;
  let showMoreBtn = stories.length < total;

  function handleChange(e) {
    setSearchText(e.target.value);
  }

  function handleKeyUp(e) {
    if (e.keyCode === 13) {
      document.getElementById("search-btn").click();
    }
  }

  const searchPath = getSearchPath(searchText, authorName, sectionName, sectionIds, authorId);

  return (
    <div styleName="root" className="body-inner-container">
      <div styleName="body">
        <div styleName="col-1">
          <div styleName="search-menu-box">
            <div styleName="search-box">
              <input
                styleName="search-text"
                type="text"
                placeholder="Search Articles"
                value={searchText}
                onChange={handleChange}
                onKeyUp={handleKeyUp}
              />
              <Link id="search-btn" href={searchPath} styleName="search-icon">
                <div style={{ width: 16, height: 16, display: "flex" }}>
                  <Search color="rgba(13, 19, 25, 0.50)" />
                </div>
              </Link>
            </div>
            <div styleName="dd-root">
              <Dropdown
                id="section-dd"
                selected={sectionName}
                options={sectionData}
                isObject
                setSelected={updateSection}
              />
              <Dropdown id="author-dd" selected={authorName} options={authorData} isObject setSelected={updateAuthor} />
              <Link id="search-btn" href={searchPath} styleName="search-icon" style={{ alignItems: "center" }}>
                <div style={{ width: 16, height: 16, display: "flex" }}>
                  <Search color="rgba(13, 19, 25, 0.50)" />
                </div>
              </Link>
            </div>
          </div>
          <div styleName="search-result-text">
            Search - {props.data.query} ({props.data.total} results)
          </div>
          <div styleName="stories-block">{storyDiv}</div>
          {showDivs && (
            <div styleName="bottom-container">
              {showMoreBtn && (
                <LazyLoad>
                  <Button variant="contained" disabled={loading} onClick={loadMore}>
                    {loading ? "Loading..." : "Load More"}
                  </Button>
                </LazyLoad>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { SearchPage };
