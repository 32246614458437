import React, { useState, useEffect } from "react";
import Button from "../../atoms/Button";
import ArchivedListItem from "../../molecules/ArchivedListItem";
import { BASE_URL } from "../../helper/constants";
import axios from "axios";

const ArchivedList = ({ articles }) => {
  const [articlesList, setArticlesList] = useState(articles.rows);
  const [page, setPage] = useState(1);

  async function loadMore() {
    const { data } = await axios.get(`${BASE_URL}/api/articles/getArchivedArticles/${page + 1}/20`);
    setPage(page + 1);
    setArticlesList([...articlesList, ...data.rows]);
  }

  return (
    <div className="grid-container" style={{ marginBottom: "20px" }}>
      <div className="flex-mobile-12" style={{ marginTop: "10px" }}>
        <h1 style={{ fontSize: "24px", fontWeight: 600 }}>Archived Articles</h1>
      </div>
      <div className="flex-mobile-12 flex-desktop-10">
        {articlesList.map(article => (
          <ArchivedListItem article={article} />
        ))}
      </div>
      <div className="flex-container" style={{ justifyContent: "center" }}>
        <Button variant="contained" onClick={loadMore}>
          Load More
        </Button>
      </div>
    </div>
  );
};

export default ArchivedList;
