import React from "react";
import "./dropdown.m.css";

function DDOption(props) {
  let rootStyle = "dd-root";
  if (props.style === "borderless") {
    rootStyle = "bdrless-dd-root";
  }
  const option = props.isObject ? props.option.name : props.option;

  return (
    <React.Fragment>
      <div onClick={() => props.click(props.option)} styleName={rootStyle}>
        {props.leaderboard ? option.split("-").join(" ") : option}
      </div>
      {!props.hideDivider && <div styleName="divider" />}
    </React.Fragment>
  );
}

export { DDOption };
