import React, { useEffect } from "react";
import { sendNonStoryPageView } from "../../helper/helper";
import ArchivedList from "../../section-templates/archived-list";

export function ArchiveListing({ data }) {
  const { articles } = data;

  useEffect(() => {
    sendNonStoryPageView();
  }, []);

  return (
    <div className="body-inner-container">
      <ArchivedList articles={articles} />
    </div>
  );
}
