import React from "react";
import { KeyboardDownIcon, ArrowDropDown } from "../../basic/icons/keyboard-down-arrow";
import { premiumCategories } from "../../helper/section-helper";
import { DDOption } from "./dd-option";
import "./dropdown.m.css";

function Dropdown(props) {
  const [showDropdown, isMenuVisible] = React.useState(false);

  function toggleMenu(e) {
    e.stopPropagation();
    isMenuVisible(!showDropdown);
  }

  function setOption(option) {
    isMenuVisible(false);
    props.setSelected(option);
  }

  let options = props.title !== "Premium" ? props.options : premiumCategories;

  const optionDiv = options.map((data, index) => {
    return (
      <DDOption
        key={index}
        option={data}
        click={setOption}
        hideDivider={options.length - 1 === index}
        style={props.style}
        isObject={props.isObject}
        leaderboard={props.leaderboard}
      />
    );
  });

  let rootStyle = "default-root";
  let optionBoxStyle = "default-option-box";
  let textStyle = "default-text";
  let iconStyle = "default-icon";
  let optionsDivStyle = "default-options-div";
  let iconColor = "rgba(13, 19, 25, 0.50)";
  let iconFill = "white";
  let ddIcon = <KeyboardDownIcon id={`dd-root-${props.id}`} color={iconColor} fillColor={iconFill} />;

  if (props.style === "borderless") {
    rootStyle = "bdrless-root";
    optionBoxStyle = "bdrless-option-box";
    textStyle = "bdrless-text";
    iconStyle = "bdrless-icon";
    iconColor = "#0D1319";
    iconFill = "#0D1319";
    optionsDivStyle = "bdrless-options-div";
    ddIcon = <ArrowDropDown id={`dd-root-${props.id}`} />;
  }

  if (typeof window !== "undefined") {
    window.onclick = function (event) {
      if (showDropdown && !event.target.matches(`#dd-root-${props.id}`)) {
        toggleMenu();
      }
    };
  }

  return (
    <div id={`dd-root-${props.id}`} className="dd-root" onClick={toggleMenu} styleName={rootStyle}>
      <div styleName={optionBoxStyle} onClick={toggleMenu}>
        <div id={`dd-root-${props.id}`} styleName={textStyle}>
          {props.leaderboard ? props.selected.split("-").join(" ") : props.selected}
        </div>
        <div id={`dd-root-${props.id}`} styleName={iconStyle}>
          {ddIcon}
        </div>
      </div>
      {showDropdown && (
        <div className="hideScrollbar" styleName={optionsDivStyle}>
          {optionDiv}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
