import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./tag.m.css";
import StoryCard from "../../molecules/StoryCard";
import { Link } from "@quintype/components";
import LazyLoad from "react-lazyload";
import StoryCardSkeleton from "../../atoms/StoryCardSkeleton/StoryCardSkeleton";
import { sendNonStoryPageView } from "../../helper/helper";

const TagPage = (props) => {
  let [page, setPage] = React.useState(1);
  let [currentPath, setPathname] = React.useState(props.currentPath);

  useEffect(() => {
    updatePage(props.data.page);
    setTimeout(() => {
      setPathname(window.location.href);
    }, 50);
    sendNonStoryPageView();
  }, []);

  function updatePage(pageNum) {
    setPage(parseInt(pageNum) + 1);
  }

  const tagData = props.data;
  let tagStories = tagData.stories;
  let storyDiv = null;
  let showDivs = false;
  const pageLimit = tagData.limit;
  let showMoreBtn = tagData.stories.length === pageLimit;

  if (tagStories.length > 0) {
    showDivs = true;
    storyDiv = tagStories
      .filter((story) => story["story-template"] === "text")
      .map((story, index) => {
        const storyItem = (
          <LazyLoad
            key={index}
            height={200}
            offset={[-200, 0]}
            debounce={500}
            placeholder={<StoryCardSkeleton />}
            styleName="tag-card-root"
          >
            <StoryCard key={index} story={story} responsive showTime cssName="tag-grid-root" />
          </LazyLoad>
        );
        return storyItem;
      });
  } else {
    storyDiv = <div style={{ marginBottom: "20px" }}>No stories found</div>;
  }

  let pathname = currentPath;
  let url;
  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
    let urlParams = url.searchParams;
    urlParams.set("page", page);
    pathname = url.toString();
  }

  return (
    <div className="body-inner-container">
      <div styleName="root">
        <div styleName="title">
          <span styleName="head-text">Articles tagged</span>
          <span styleName="head-tag-text">{tagData.tag.name}</span>
        </div>
        <div styleName="body">
          <div styleName="col-1">
            {storyDiv}
            {showDivs && (
              <div styleName="bottom-container">
                {showMoreBtn && (
                  <LazyLoad style={{ marginBottom: "24px" }}>
                    <Link href={pathname} styleName="load-more-btn">
                      Load More
                    </Link>
                  </LazyLoad>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { TagPage };
TagPage.propTypes = {
  data: PropTypes.object,
};
