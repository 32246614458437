import { Link } from "@quintype/components";
import React, { useEffect } from "react";
import { sendNonStoryPageView } from "../../helper/helper";
import "./not-found.m.css";

const NotFoundPage = () => {
  useEffect(() => {
    sendNonStoryPageView();
  }, []);
  return (
    <div styleName="root">
      <div styleName="text-404">404</div>
      <div styleName="not-found-text">Page Not Found</div>
      <div styleName="desc">
        The page you are looking for might have been removed, had its name changed or is temporarily unavailable.
      </div>
      <Link styleName="btn-text" href="/">
        Go to Homepage
      </Link>
    </div>
  );
};

export { NotFoundPage };
